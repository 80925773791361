import { useMediaQuery } from '@vueuse/core'
import { extraSmall, small, mobile, tablet, desktop, lowScreen } from '~/helpers/breakpoints'

export const useScreenSizeChecker = () => {
  const isExtraSmall = useMediaQuery(extraSmall)
  const isSmall = useMediaQuery(small)
  const isMobile = useMediaQuery(mobile)
  const isTablet = useMediaQuery(tablet)
  const isDesktop = useMediaQuery(desktop)
  const isLowHeightScreen = useMediaQuery(lowScreen)

  return { isExtraSmall, isSmall, isMobile, isTablet, isLowHeightScreen, isDesktop }
}
